<template>
  <div class="px-2 py-3 rounded-sm border bc-gray-200">
    <a-tree
      multiple
      treeCheckable="tree"
      checkable
      @expand="onExpand"
      :expandedKeys="expandedKeysss"
      :checkedKeys="checkedKeys"
      :allowClear="true"
      :auto-expand-parent="true"
      :checkStrictly="true"
      :default-expanded-keys="defaultDepartKeys"
      :default-selected-keys="defaultDepartKeys"
      :default-checked-keys="defaultDepartKeys"
      @check="onCheck"
      :dropdownStyle="{maxHeight:'200px',overflow:'auto'}"
      :treeData="departTree"
      placeholder="请选择上级部门"
    >
    </a-tree>
  </div>
</template>

<script>
import { queryIdTree } from '@/api/api'
export default {
  props: {
    defaultDepartKeys: {
      type: Array,
      default: () => []
    },
  },
  data(){
    return{
      
      checkedKeys:[], // 存储选中的部门id
      expandedKeysss:[],//展开的节点
      userId:"", // 存储用户id
      model:{}, // 存储SysUserDepartsVO表
      userDepartModel:{userId:'',departIdList:[]}, // 存储用户id一对多部门信息的对象
      departList:[], // 存储部门信息
      departTree:[],
    }
  },
  methods:{
    // 选择部门时作用的API
    onCheck(checkedKeys, info){
      this.departList = [];
      this.checkedKeys = checkedKeys.checked;
      let checkedNodes = info.checkedNodes;
      for (let i = 0; i < checkedNodes.length; i++) {
        let de = checkedNodes[i].data.props;
        let depart = {key:"",value:"",title:""};
        depart.key = de.value;
        depart.value = de.value;
        depart.title = de.title;
        this.departList.push(depart);
      }
      this.$emit('change',checkedKeys, this.departList, info)
      // console.log('onCheck', checkedKeys, info);
    },
    queryDepartTree(){
      queryIdTree().then((res)=>{
        
        if(res.success){
          this.departTree = res.data;
          if(this.checkedKeys&&this.checkedKeys.length >0){
            let treekey=[];
            let arr=res.data;
            if(arr&&arr.length>0){
              arr.forEach(item => {
                treekey.push(item.key);
              })
              this.expandedKeysss = treekey
            }
          }
        }
      })
    },

    onExpand(expandedKeys){
      this.expandedKeysss = expandedKeys;
    },
  },
  created(){
    this.queryDepartTree()
  }
}
</script>

<style lang='stylus'>

</style>
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "px-2 py-3 rounded-sm border bc-gray-200" },
    [
      _c("a-tree", {
        attrs: {
          multiple: "",
          treeCheckable: "tree",
          checkable: "",
          expandedKeys: _vm.expandedKeysss,
          checkedKeys: _vm.checkedKeys,
          allowClear: true,
          "auto-expand-parent": true,
          checkStrictly: true,
          "default-expanded-keys": _vm.defaultDepartKeys,
          "default-selected-keys": _vm.defaultDepartKeys,
          "default-checked-keys": _vm.defaultDepartKeys,
          dropdownStyle: { maxHeight: "200px", overflow: "auto" },
          treeData: _vm.departTree,
          placeholder: "请选择上级部门",
        },
        on: { expand: _vm.onExpand, check: _vm.onCheck },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }